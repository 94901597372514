<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="grid"
        title="감사팀 목록"
        tableId="grid"
        :columns="grid.columns"
        :data="grid.data"
        selection="multiple"
        rowKey="selfInspectionOrgId"
        :editable="editable && !disabled"
        :isFullScreen="false"
        :columnSetting="false"
        :filtering="false"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
              <c-btn v-if="editable && !disabled" label="LBLADD" icon="add" @btnClicked="addrow" />
              <c-btn v-if="editable && !disabled" label="LBLREMOVE" icon="remove" @btnClicked="removerow" />
              <c-btn v-if="editable && !disabled" label="LBLSAVE" icon="save" @btnClicked="saverow" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'plan-team',
  props: {
    param: {
      type: Object,
      default: () => ({
        selfInspectionResultId: '',
        selfInspectionStatusCd: '',
        sysRevision: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      listUrl: '',
      insertUrl: '',
      updateUrl: '',
      grid: {
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            label: '소속',
            align: 'center',
            style: 'width: 200px',
            sortable: false,
          },
          {
            name: 'jobName',
            field: 'jobName',
            label: 'LBLPOSITION',
            align: 'center',
            style: 'width: 200px',
            sortable: false,
          },
          {
            name: 'userName',
            field: 'userName',
            label: '감사원명',
            align: 'center',
            style: 'width: 200px',
            sortable: false,
          },
          {
            name: 'workName',
            field: 'workName',
            label: '담당업무',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'userMajor',
            field: 'userMajor',
            label: '전공',
            align: 'center',
            style: 'width: 200px',
            type: 'text',
            sortable: false,
          },
        ],
        data: [],
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        isFull: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
      return Boolean(this.param.selfInspectionStatusCd) && this.param.selfInspectionStatusCd !== 'SISC000001'
    }
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sop.pca.result.org.list.url;
      this.insertUrl = transactionConfig.sop.pca.result.org.save.url;
      this.deleteUrl = transactionConfig.sop.pca.result.org.delete.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {selfInspectionResultId: this.param.selfInspectionResultId};
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    addrow() {
      this.popupOptions.title = '사용자 검색';
      this.popupOptions.param = {
        type: 'multiple',
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeUser;
    },
    closeUser(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.grid.data, { userId: item.userId }) === -1) {
            this.grid.data.push({
              editFlag: 'C',
              selfInspectionResultId: this.param.selfInspectionResultId,  // 자체감사 계획 일련번호
              selfInspectionOrgId: uid(),
              userId: item.userId,  
              userName: item.userName,  
              workName: '',
              userMajor: '',
              deptName: item.deptName,
              jobName: item.jobName,
            })
          }
        })
      }
    },
    saverow() {
      let saveData = this.grid.data.filter( x => {
        return x.editFlag == 'C' || x.editFlag == 'U'
      });
      if(saveData.length > 0) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGSAVE', // 저장하시겠습니까?
          type: 'info',
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.insertUrl;
            this.$http.type = 'POST';
            this.$http.param = saveData;
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.getList();
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      } else {
        window.getApp.$emit('ALERT', {
          title: '안내 ', /* 안내 */
          message: 'MSGNOCHANGEDATA', // 변경된 데이터가 없습니다.,
          type: 'info', // success / info / warning / error
        });
      }
    },
    removerow() {
      let selectData = this.$refs['grid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, item);
              })
              this.$refs['grid'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>
